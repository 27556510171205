"use client";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import "@/app/[lang]/styles/swiper.css";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useDispatchSetUseAvailableBalance } from "@/hooks";
import "./index.css";

export default function HomeSlider({ banners = [] }) {
  const [loading, setLoading] = useState(true);
  const dispatchSetCheckedAvailableBalance =
    useDispatchSetUseAvailableBalance();
  useEffect(() => {
    dispatchSetCheckedAvailableBalance(null);
    if (banners?.length > 0) {
      setLoading(false);
    }
  }, [dispatchSetCheckedAvailableBalance, banners]);

  return (
    <div className="container p-0 p-md-auto">
      <div className="mb-3 mx-md-2">
        {loading ? (
          <div className="skeleton-container">
            <div className="skeleton-loader bg-gray-200"></div>
          </div>
        ) : (
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={true}
            pagination={true}
            modules={[Autoplay, Pagination]}
            className="custom_HomeSwiper"
          >
            {banners?.map((item, index) => {
              return item?.isDisabled === false ? (
                <SwiperSlide
                  key={`${item.id}-${index}`}
                  className="homepage_swiperslider_div"
                >
                  <Link href={item.link}>
                    <Image
                      src={item?.image}
                      alt={"Desktop Banner"}
                      width={1250}
                      height={350}
                      className="homepage_image"
                      priority={index === 0}
                      loading={index === 0 ? "eager" : "lazy"}
                    />
                  </Link>
                </SwiperSlide>
              ) : null;
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
}

HomeSlider.propTypes = {
  /**
   * Banners to be displayed on the homepage slider
   */
  banners: PropTypes.array.isRequired,
};
