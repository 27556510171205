import React from "react";
import "./index.css";

const SkeletonHome = ({ type, data }) => {
  const Circles = ({ count = 5 }) => (
    <div className="flex flex-wrap mb-2 justify-center">
      <div className="w-full h-10 bg-gray-200 lines mb-2 rounded-t-xl"></div>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="w-10 h-10 bg-gray-200 lines rounded-full mx-1.5 my-1"
        ></div>
      ))}
    </div>
  );

  switch (type) {
    case "lineCard":
      return (
        <div className="col-lg-7">
          <div className="skeleton flex flex-col border border-gray-200 p-3 rounded-xl">
            <div className="flex justify-between items-center skeleton-wrapper">
              <div className="w-1/2">
                <div className="h-4 bg-gray-200 rounded-md w-full mb-2 lines"></div>
                <div className="h-10 bg-gray-200 rounded-md w-full mb-2 lines"></div>
                <div className="h-4 bg-gray-200 rounded-md w-full lines"></div>
              </div>
              <div className="w-1/4 flex items-center justify-between">
                <div className="w-10 h-10 bg-gray-200 rounded-full lines"></div>
                <div className="h-10 bg-gray-200 rounded-md w-1/4 lines mx-2"></div>
                <div className="w-10 h-10 bg-gray-200 rounded-full lines"></div>
              </div>
            </div>
          </div>

          <div className="mt-3 flex justify-between circle-wrapper">
            <div className="skeleton-one skeleton flex justify-between w-full rounded-xl">
              <Circles count={45} />
            </div>
            <div className="skeleton flex justify-between w-full rounded-xl">
              <Circles count={45} />
            </div>
          </div>
          <div className="mt-3">
            <div className="h-20 bg-gray-200 rounded-xl w-full mb-2 lines"></div>
          </div>
        </div>
      );
    case "countdown":
      return (
        <div className="col-lg-5 pl-3">
          <div className="skeleton flex flex-col border border-gray-200 rounded-lg">
            <div className="h-12 rounded-md mt-0 lines flex items-center justify-center">
              <div className="h-4 bg-gray-200 lines w-50"></div>
            </div>
            <div className="bg-gray-200 p-4">
              <div className="flex items-center justify-between">
                <div className="w-16 h-16 md:w-20 md:h-20 bg-white rounded-full lines"></div>
                <div className="w-16 h-16 md:w-20 md:h-20 bg-white rounded-full lines"></div>
                <div className="w-16 h-16 md:w-20 md:h-20 bg-white rounded-full lines"></div>
                <div className="w-16 h-16 md:w-20 md:h-20 bg-white rounded-full lines"></div>
              </div>
              <div className="h-24 bg-white p-4 rounded-full flex items-center justify-between mt-3 lines flex-col">
                <div className="h-4 bg-gray-200 lines w-50"></div>
                <div className="h-4 bg-gray-200 lines w-75"></div>
              </div>
              <div className="h-24 bg-white p-4 rounded-full flex items-center justify-between mt-3 lines flex-col">
                <div className="h-4 bg-gray-200 lines w-50"></div>
                <div className="h-4 bg-gray-200 lines w-75"></div>
              </div>
              <div className="flex justify-center mt-3">
                <div className="h-2 bg-gray-200 rounded-md w-1/4 lines"></div>
              </div>
              <div className="h-14 my-3 mx-3 bg-white rounded-md lines"></div>
            </div>
          </div>

          <div className="bg-gray-200 p-3 h-24 rounded-b-xl mt-3"></div>
        </div>
      );
    case "story":
      return (
        <div className="col-xl-4 col-lg-12">
          <div
            className="h-full skeleton skeleton-stories"
            style={{ width: "100%", height: "97%" }}
          >
            <div className="h-52 mb-5 px-3 bg-gray-200 w-100 lines"></div>
          </div>
        </div>
      );

    case "download":
      return (
        <div className="container">
          <div className="h-52 mb-5 px-3 bg-gray-200 w-100 lines"></div>
        </div>
      );

    case "results":
      return (
        <div
          className={
            data?.isFullWidth ? "col-xl-12 col-lg-12" : "col-xl-8 col-lg-12"
          }
        >
          <div className="skeleton flex flex-col border border-gray-200 mt-3 rounded-xl">
            <div className="h-12 bg-gray-200 rounded-t-md mt-0 lines flex items-center justify-center">
              <div className="h-4 bg-white lines w-50"></div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="p-3 flex items-center justify-center">
                  <div className="h-6 bg-gray-200 lines w-75"></div>
                </div>
                <Circles count={5} />
                <div className="row">
                  <div className="col-md-12">
                    {Array(5)
                      .fill()
                      .map((_, index) => (
                        <div
                          key={index}
                          className="h-12 bg-gray-200 rounded-md lines w-90 mx-3 mt-3"
                        ></div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="p-3 flex items-center justify-center">
                  <div className="h-6 bg-gray-200 lines w-75"></div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {Array(6)
                      .fill()
                      .map((_, index) => (
                        <div
                          key={index}
                          className="h-12 bg-gray-200 rounded-md lines w-90 mx-3 mt-3"
                        ></div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    default:
      return <div>Loading...</div>;
  }
};

export default React.memo(SkeletonHome);
