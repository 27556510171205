"use client";
import PropTypes from "prop-types";
import { Suspense, useEffect } from "react";
import dynamic from "next/dynamic";
import { useDispatch } from "react-redux";
import HomeSlider from "./slider";
import { setShowFavouritesContent } from "@/redux/slices/showFavouritesModalSlice";
import { setAddFavouritesContent } from "@/redux/slices/addFavouritesModalSlice";
import { useSelectGameGroups } from "@/hooks";
import { DIGITS } from "@/utils/constants";
import SkeletonHome from "./skeleton/skeleton-home";
import "./index.css";

export default function Home({ data = null }) {
  //dynamic imports
  const DynamicHomeStory = dynamic(() => import("./stories"), {
    ssr: false,
    loading: () => <SkeletonHome type="story" />,
  });
  const DynamicHomeResults = dynamic(() => import("./results"), {
    ssr: false,
    loading: () => (
      <SkeletonHome type="results" data={data?.isStoriesVideoDisabled} />
    ),
  });
  const DynamicHomeDownload = dynamic(() => import("./download"), {
    ssr: false,
    loading: () => <SkeletonHome type="download" />,
  });
  const DynamicHomeLineCard = dynamic(() => import("./line-card"), {
    ssr: false,
    loading: () => <SkeletonHome type="lineCard" />,
  });
  const DynamicHomeCountdown = dynamic(() => import("./countdown"), {
    ssr: false,
    loading: () => <SkeletonHome type="countdown" />,
  });
  const DynamicHomeWinnersMobile = dynamic(() => import("./winners-mobile"), {
    ssr: false,
  });
  const videoSection = data?.isStoriesVideoDisabled;
  const dispatch = useDispatch();
  const gamegroups = useSelectGameGroups();

  useEffect(() => {
    if (data?.showFavourites?.id > 0) {
      dispatch(
        setShowFavouritesContent({
          title: data?.showFavourites?.title || "",
          emptyFavouritesRemarks:
            data?.showFavourites?.emptyFavouritesRemarks || "",
        })
      );
    }
  }, [data?.showFavourites, dispatch]);
  useEffect(() => {
    if (data?.addFavourites?.id > 0) {
      dispatch(
        setAddFavouritesContent({
          title: data?.addFavourites?.title || "",
          skipButton: data?.addFavourites?.skipButton || {},
          saveButton: data?.addFavourites?.saveButton || {},
          favNameNotThere: data?.addFavourites?.favNameNotThere || "",
          limitExceededMsg: data?.addFavourites?.limitExceededMsg || "",
          favNameAlreadyThere: data?.addFavourites?.favNameAlreadyThere || "",
          favNoNotThere: data?.addFavourites?.favNoNotThere || "",
          favNoThere: data?.addFavourites?.favNoThere || "",
          FavNoDuplicate: data?.addFavourites?.FavNoDuplicate || "",
          FavNamePlaceholder: data?.addFavourites?.FavNamePlaceholder || "",
        })
      );
    }
  }, [data?.addFavourites, dispatch]);
  return (
    <>
      {data?.banners ? <HomeSlider banners={data?.banners || []} /> : ""}
      <div className="remodal__wrapper"></div>
      <div className="remodal__wrapper"></div>
      <section className="hp-navigation">
        <div className="container">
          <div className="hp-navigation__inner">
            <div className="row">
              <DynamicHomeLineCard
                playInfo={data?.playInfo || {}}
                currency={data?.topPrize?.currency}
                selectNo={
                  gamegroups[0]?.gameBoards[0]?.drawnNumbers || DIGITS.FIVE
                }
                totalLineDigits={gamegroups[0]?.gameBoards[0]?.maxNumber}
              />

              <DynamicHomeCountdown
                countDownTimer={data?.countDownTimer || {}}
                topPrize={data?.topPrize || {}}
                promoPrize={data?.promoPrize || {}}
                ctaPrizes={data?.ctaPrizes || {}}
                totalWinnersLabel={data?.totalWinnersLabel || ""}
              />

              {data?.showStorySection === true && videoSection === false ? (
                <DynamicHomeStory data={data?.stories || {}} />
              ) : (
                ""
              )}
              {data?.showStorySection === true ? (
                <DynamicHomeResults
                  currency={data?.topPrize?.currency}
                  data={data?.results || {}}
                  isFullWidth={videoSection === true ? true : false}
                />
              ) : (
                ""
              )}

              <DynamicHomeWinnersMobile label={data?.totalWinnersLabel || ""} />
            </div>
          </div>
        </div>
      </section>
      {data?.showDownloadSection === true ? (
        <DynamicHomeDownload
          desktopImage={data?.downloadDesktopImage || ""}
          mobileImage={data?.downloadMobileImage || ""}
          appStoreUrl={data?.downloadAppStore || ""}
          googlePlayUrl={data?.downloadGooglePlay || ""}
        />
      ) : (
        ""
      )}
    </>
  );
}

Home.propTypes = {
  data: PropTypes.object.isRequired,
};
